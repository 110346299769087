export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_REPORT_LIST_SUCCESS":
    case "GET_REPORT_LIST_ERROR":
      return {
        ...state,
        GetReportListResponse: action.updatePayload,
      };
    case "GET_BRAND_LIST_SUCCESS":
    case "GET_BRAND_LIST_ERROR":
      return {
        ...state,
        GetBrandListResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "GET_USER_KEYWORD_SUCCESS":
    case "GET_USER_KEYWORD_ERROR":
      return {
        ...state,
        GetUserKeywordResponse: action.updatePayload,
      };
    case "DELETE_USER_KEYWORD_SUCCESS":
    case "DELETE_USER_KEYWORD_ERROR":
      return {
        ...state,
        DeleteUserKeywordResponse: action.updatePayload,
      };
    case "KEYWORD_USER_STORE_SUCCESS":
    case "KEYWORD_USER_STORE_ERROR":
      return {
        ...state,
        KeywordUserStoreResponse: action.updatePayload,
      };
    case "UPDATE_TO_AMAZON_SUCCESS":
    case "UPDATE_TO_AMAZON_ERROR":
      return {
        ...state,
        UpdatetoAmazonResponse: action.updatePayload,
      };
    case "STORE_ANALYSIS_SUCCESS":
    case "STORE_ANALYSIS_ERROR":
      return {
        ...state,
        StoreAnalysisResponse: action.updatePayload,
      };

    case "PRODUCT_ADD_UNLISTED_PRODUCT_SUCCESS":
    case "PRODUCT_ADD_UNLISTED_PRODUCT_ERROR":
      return {
        ...state,
        ProductAddUnlistedProductResponse: action.updatePayload,
      };
    case "CRITERIA_ANALYSIS_SUCCESS":
    case "CRITERIA_ANALYSIS_ERROR":
      return {
        ...state,
        CriteriaAnalysisListResponse: action.updatePayload,
      };
    case "PRODUCT_DETAILS_VIEW_SUCCESS":
    case "PRODUCT_DETAILS_VIEW_ERROR":
      return {
        ...state,
        ProductDetailsViewResponse: action.updatePayload,
      };
    case "PRODUCT_KEYWORDS_VIEW_SUCCESS":
    case "PRODUCT_KEYWORDS_VIEW_ERROR":
      return {
        ...state,
        ProductKeywordsViewResponse: action.updatePayload,
      };
    case "CRITERIA_ANALYSIS_RECOMMENDATION_TITLE_SUCCESS":
    case "CRITERIA_ANALYSIS_RECOMMENDATION_TITLE_ERROR":
      return {
        ...state,
        CriteriaAnalysisRecommendationTitleResponse: action.updatePayload,
      };
    case "CRITERIA_DESCRIPTION_RECOMMENDATION_SUCCESS":
    case "CRITERIA_DESCRIPTION_RECOMMENDATION_ERROR":
      return {
        ...state,
        CriteriaAnalysisRecommendationDescriptionResponse: action.updatePayload,
      };
    case "CRITERIA_DESCRIPTION_ANALYSIS_SUCCESS":
    case "CRITERIA_DESCRIPTION_ANALYSIS_ERROR":
      return {
        ...state,
        CriteriaAnalysisDescriptionResponse: action.updatePayload,
      };
    case "BULLETS_ANALYSIS_ACTION_SUCCESS":
    case "BULLETS_ANALYSIS_ACTION_ERROR":
      return {
        ...state,
        BulletsAnalysisActionResponse: action.updatePayload,
      };
    case "RECOMMENDATION_BULLETS_ACTION_SUCCESS":
    case "RECOMMENDATION_BULLETS_ACTION_ERROR":
      return {
        ...state,
        RecommendationBulletsResponse: action.updatePayload,
      };
    case "PRODUCT_ANALYSIS_ACTION_SUCCESS":
    case "PRODUCT_ANALYSIS_ACTION_ERROR":
      return {
        ...state,
        ProductAnalysisActionResponse: action.updatePayload,
      };
    case "COMPETITOR_REIVIEW_ANALYSIS_SUCCESS":
    case "COMPETITOR_REIVIEW_ANALYSIS_ERROR":
      return {
        ...state,
        CompetitorReviewAnalysisResponse: action.updatePayload,
      };
    case "CHAT_GPT_ANALYSIS_SUCCESS":
    case "CHAT_GPT_ANALYSIS_ERROR":
      return {
        ...state,
        ChatGPTAnalysisResponse: action.updatePayload,
      };
    case "MY_REVIEW_ANALYSIS_SUCCESS":
    case "MY_REVIEW_ANALYSIS_ERROR":
      return {
        ...state,
        MyReviewAnalysisResponse: action.updatePayload,
      };
    case "FAKE_ACTION_PRODUCT_MASTER":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
